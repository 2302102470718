import React from "react";
import { useParams } from "react-router-dom";
import subTour from "../../../Data/Tours/SubTours.json";
import Cards from "../../Home/components/Cards";
import Error from "../../Error/components/Error";
import Subhero from "../../About/components/Subhero";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Sliding from "../../Home/components/Slider/Sliding";
import "../style/SubTourTempelate.css";
import HelmetForSEO from "../../HelmetForSEO";
const SubTourTempelate = () => {
  let params = useParams();
  const URRL = subTour[params.subTour]?.url;
  console.log(URRL);
  if (!URRL) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  const Toggle = (subTour) => {
    return subTour ? (
      // <div className="sub-tour">
      //   <h2 className="heading sliding-heading bg-accent">{subTour.heading}</h2>
      //   <Sliding array={subTour.activities} />
      // </div>
      <div className={`card-wrapper`}>
         
        {
          subTour.activities.map((trip, index) => (
            <Cards
              key={index}
              link={trip.link}
              image={trip.image}
              name={trip.name}
              days={trip.days}
              facts={trip.facts}
              price={trip.price}
              review={trip.review}
            />
          ))}
      </div>
    ) : (
      <>Sub Tour not Found</>
    );
  };
  return (
    <div>
      <HelmetForSEO
        url={`/tours/${subTour[params.subTour]?.url}`}
        title={`${subTour[params.subTour]?.subhero.title} - Big Sky Treks`}
        keywords={subTour[params.subTour].seo.keywords}
        description={subTour[params.subTour].seo.description}
      />
      <Subhero
        title={subTour[params.subTour]?.subhero.title}
        image={subTour[params.subTour]?.subhero.image}
      />
      <div className="main-content">
        <div className="sub-main">
          <div className="overview">
            <h2 className="heading">
              {subTour[params.subTour]?.overview.heading}
            </h2>
            <p
              className="format"
              dangerouslySetInnerHTML={{
                __html: subTour[params.subTour]?.overview.paragraph,
              }}
            />
          </div>
        </div>
        {Toggle(subTour[params.subTour].subtours)}
      </div>
    </div>
  );
};

export default SubTourTempelate;
