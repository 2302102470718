import React, { useState } from 'react';
import '../style/ItenaryCard.css'; // Ensure this file exists for the CSS styles

const ItenaryCard = (props) => {
    const [activeItenary, setActiveItenary] = useState(null);

    const handleToggle = (index) => {
        setActiveItenary(activeItenary === index ? null : index);
    };

    return (
        <div className="itenary-card-container">
            {props.array.map((item, index) => (
                <div
                    className="itenary-card"
                    key={index}
                >
                    <div
                        className="itenary-heading"
                        onClick={() => handleToggle(index)}
                    >
                        <div className="heading-content">
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/1566/1566070.png"
                                alt="Icon"
                                className="heading-icon"
                            />
                            <h3 className="heading-title">{item.dayheading}</h3>
                        </div>

                    </div>

                    {activeItenary === index && (
                        <div className="itenary-details">
                            <ol className="itenary-list">
                                {item.lists.map((item1, index1) => (
                                    <li key={index1} dangerouslySetInnerHTML={{ __html: item1 }} />
                                ))}
                            </ol>
                            {item.note && <div className="itenary-note" dangerouslySetInnerHTML={{ __html: item.note }} />}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ItenaryCard;
