import React from 'react'
import Subhero from '../../About/components/Subhero'
import Calculator from './Calulator'
import ItenaryCard from './ItenaryCard'
import Trekking from "../../../Data/Trekking/Trekking.json";
import SimilarTrek from '../../../Data/Trekking/similarTrek.json'
import { useParams } from 'react-router-dom';
import Error from '../../Error/components/Error';
const SimilarTripTempelate = () => {


const params = useParams();
  const URRL = SimilarTrek[params.id];

  if (!URRL) {
    return (
      <div>
        <Error />
      </div>
    )
  }

    
  return (
    <div className="main-content" style={{ padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '10px' }}>
    <button
      onClick={() => window.history.back()}
      style={{
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '5px',
        cursor: 'pointer',
        marginBottom: '20px',
      }}
    >
      Back
    </button>
    
    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
      <div>
      <h1 className="heading"> 15 Days Everest Base Camp</h1>
      <div className="itenary" style={{ flex: '2', padding: '15px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        
        <ItenaryCard array={URRL.itenary} />
      </div>
      </div>
      <div className="calculator">
        <Calculator />
      </div>
    </div>
  </div>
  

  )
}

export default SimilarTripTempelate