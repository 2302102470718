// import React from 'react'
// import { BsStarFill } from "react-icons/bs";
// import { SlCalender } from "react-icons/sl";
// import { Link } from 'react-router-dom';
// import '../style/Cards.css'
// const Cards = (props) => {
//   return (

//     <div className='wrapper'>
//       <Link to={props.link} className="link">
//     <div className="image">
      
//     <img src={props.image} alt=""  className='img'/>
//     </div>
//     <div className="body">
//       <div className="name">
//         <h3>{props.name}</h3>
//       </div>
//       <div className="footer">
//         <div className="days">
//         <SlCalender/> {props.days}
//         </div>
//         <div className="reviews" >
//         <BsStarFill className='gold'/><BsStarFill className='gold'/><BsStarFill className='gold'/><BsStarFill className='gold'/><BsStarFill className='gold'/>
//         </div>
//       </div>
//     </div>
//     </Link>
//     </div>

//   )
// }

// export default Cards

import React from 'react'
import '../style/Cards.css'
import { Link } from 'react-router-dom'
import { TiStarFullOutline } from "react-icons/ti";



const Cards = ({name, facts, image, link, price=1000,review=15, days}) => {
  price=parseInt(price)
  return (

    <Link to={link} style={{width:"376px",  border:"1px solid var(--primary-color)",padding:"5px", position:"relative", textDecoration:"none", color:"black"}} className='big-sky-card'>
      <div className="card-header" height="280px">
      <div className="card-header-image"  style={{backgroundImage:`url(${image})`, width:"100%", height:"376px"}}>

      </div>
      <h3 className="facts" style={{backgroundColor:"var(--primary-color) ", position:"absolute", top:"345px", padding:"10px", color:"white",  fontSize:"15px", fontWeight:"500"}}>
      {facts}
      </h3>
    </div>
    <div className="card-footer">
      <div className="card-heading">
     <h2 style={{fontFamily:"'Times New Roman', Times, serif;", color:"var(--primary-color)"}}>{name}</h2>
      </div>
      
      <div className="price" style={{fontSize:"20px", marginTop:"-10px"}}>
      Starting From: <span style={{color:"var(--primary-color)", fontWeight:"bold"}}>${price}</span> <span style={{position:"relative"}}><span>${price + 0.2*price}</span> <div className="underline" style={{border:"1px solid black", position:"absolute", width:"50px", top:"13px", left:"0px"}}></div></span> <span style={{fontSize:"16px"}}> per person</span>
      </div>
      <div className="duration-review" style={{display:"flex", justifyContent:"space-between", marginTop:"10px"}}>
      <div className="duration">
        Duration:{days} Days
      </div>
      <div className="review">
      <span style={{color:"var(--primary-color)", fontWeight:"bold", fontSize:"20px"}}><TiStarFullOutline/>5.0 </span><span>{review} Review</span>
      </div>
      </div>
      </div>

    </Link>

  )
}

export default Cards








