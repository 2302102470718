// import React, { useState } from 'react'
// import navigationBar from '../../../Data/Nav/NavBarData.json'
// import { NavLink } from 'react-router-dom';
// const Navigation = () => {
//     const [isTrekkingMenuOpen, setIsTrekkingMenuOpen] = useState(false);
//     const [isActivitiesMenuOpen, setIsActivitiesMenuOpen] = useState(false);
//     const [isTourMenuOpen, setIsTourMenuOpen] = useState(false);
//   return (
//     <>
//     <div>logo</div>
//     <div>
//         <ul>
//             <li><NavLink to="/">Home</NavLink></li>
//             <li>
//                 <div
//                 onMouseEnter={() => setIsTrekkingMenuOpen(true)}
//                 onMouseLeave={() => setIsTrekkingMenuOpen(false)}>Trekking</div>

//                 <div onMouseEnter={() => setIsTrekkingMenuOpen(true)}
//                     onMouseLeave={() => setIsTrekkingMenuOpen(false)}
//                     >

//                         {isTrekkingMenuOpen &&<><span>Everest Region</span> <ul>
//                         {navigationBar['everest-region'].map((item, index) => (
//                             <li key={index} onClick={() => setIsTrekkingMenuOpen(false)}><NavLink to={item.link}>{item.name}</NavLink></li>
//                         ))}

//                     </ul>
//                     <span>Annapurna Region</span>
//                     <ul>
//                         {
//                             navigationBar['annapurna-region'].map((item, index) => (
//                                 <li key={index} onClick={() => setIsTrekkingMenuOpen(false)}><NavLink to={item.link}>{item.name}</NavLink></li>
//                             ))
//                         }
//                     </ul>
//                     <span>Langtang Region</span>
//                     <ul>
//                         {
//                             navigationBar['langtang-region'].map((item, index) => (
//                                 <li key={index} onClick={() => setIsTrekkingMenuOpen(false)}><NavLink to={item.link}>{item.name}</NavLink></li>
//                             ))
//                         }
//                     </ul>
//                     <span>Manaslu Region</span>
//                     <ul>
//                         {
//                             navigationBar['manaslu-region'].map((item, index) => (
//                                 <li key={index} onClick={() => isActivitiesMenuOpen(false)}><NavLink to={item.link}>{item.name}</NavLink></li>
//                             ))
//                         }
//                     </ul>
//                     </>
//                 }

//                 </div>
//             </li>
//             <li>
//                 <div
//                 onMouseEnter={() => setIsActivitiesMenuOpen(true)}
//                 onMouseLeave={() => setIsActivitiesMenuOpen(false)}>Activities</div>

//                 <div onMouseEnter={() => setIsActivitiesMenuOpen(true)}
//                     onMouseLeave={() => setIsActivitiesMenuOpen(false)}
//                     >

//                         {isActivitiesMenuOpen &&<><ul>
//                         {navigationBar.activities.map((item, index) => (
//                             <li key={index} onClick={() => setIsActivitiesMenuOpen(false)}><NavLink to={item.link}>{item.name}</NavLink></li>
//                         ))}
//                     </ul>

//                     </>
//                 }

//                 </div>
//             </li>
//             <li>
//                 <div
//                 onMouseEnter={() => setIsTourMenuOpen(true)}
//                 onMouseLeave={() => setIsTourMenuOpen(false)}>Tours</div>

//                 <div onMouseEnter={() => setIsTourMenuOpen(true)}
//                     onMouseLeave={() => setIsActivitiesMenuOpen(false)}
//                     >

//                         {isTourMenuOpen &&<><ul>
//                         {navigationBar.tours.map((item, index) => (
//                             <li key={index} onClick={() => setIsTourMenuOpen(false)}><NavLink to={item.link}>{item.name}</NavLink></li>
//                         ))}
//                     </ul>

//                     </>
//                 }

//                 </div>
//             </li>
//             <li><NavLink to="/about">About Us</NavLink></li>
//             <li><NavLink to="/blogs">Blog</NavLink></li>
//             <li><NavLink to="/contact">Contact Us</NavLink></li>

//         </ul>
//     </div>
//     </>
//   )
// }

// export default Navigation

import React, { useState } from "react";
import navigationBar from "../../../Data/Nav/NavBarData.json";
import { Link, NavLink } from "react-router-dom";
import Search from "./Search";
import { CiCircleChevRight } from "react-icons/ci";
import "../style/Nav.css";
import "../style/Navigation.css";
const Navigation = () => {
  const [isTrekkingMenuOpen, setIsTrekkingMenuOpen] = useState(false);
  const [isActivitiesMenuOpen, setIsActivitiesMenuOpen] = useState(false);
  const [isTourMenuOpen, setIsTourMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isParallel, setIsParallel] = useState(true);
  const [isTrekkingMenuOpens, setIsTrekkingMenuOpens] = useState(false);
  const [isActivitiesMenuOpens, setIsActivitiesMenuOpens] = useState(false);
  const [isTourMenuOpens, setIsTourMenuOpens] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  function toggleNav() {
    setIsMenuOpen(!isMenuOpen);
    setIsParallel(!isParallel);
    setIsActivitiesMenuOpens(false);
    setIsTrekkingMenuOpens(false);
    setIsTourMenuOpens(false);
  }

  const trueFalse = (isOpen, setIsOpen) => {
    setIsOpen(!isOpen);
  };
  const Condition = (otherCondition, setOtherCondition) => {
    otherCondition ? setOtherCondition(false) : setOtherCondition(false);
  };
  const toggleSubMenu = (
    isOpen,
    setIsOpen,
    otherCondition,
    setOtherCondition,
    anotherCondition,
    setAnotherCondition
  ) => {
    trueFalse(isOpen, setIsOpen);
    Condition(otherCondition, setOtherCondition);
    Condition(anotherCondition, setAnotherCondition);
  };
  return (
    <>
    <div
      style={{
        display: "flex",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 100,
        height: "66px",
        padding: "1rem",
        backgroundColor: "var(--primary-color)",
        gap: "8rem",
      }}
    >
      <div>
        <img
          src="/Image/Nav/big-sky-treks-logo.jpg"
          alt=""
          style={{ width: "75px" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: "5rem",

          alignItems: "center",
        }}
      >
        <ul style={{ display: "flex", gap: "2rem", listStyle: "none" }}>
          <li>
            <NavLink to="/" className={"navigation-link"}>
              Home
            </NavLink>
          </li>
          <li>
            <div
              onMouseEnter={() => setIsTrekkingMenuOpen(true)}
              onMouseLeave={() => setIsTrekkingMenuOpen(false)}
              className="navigation-link"
            >
              Trekking
            </div>

            <div
              onMouseEnter={() => setIsTrekkingMenuOpen(true)}
              onMouseLeave={() => setIsTrekkingMenuOpen(false)}
            >
              {isTrekkingMenuOpen && (
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    gap: "1rem",
                    backgroundColor: "var(--primary-color)",
                    padding: "16px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}
                    >
                      Everest Region
                    </span>
                    <ul style={{ position: "relative", left: "-40px"}}>
                      {navigationBar["everest-region"].map((item, index) => (
                        <NavLink
                        to={item.link}
                        style={{
                          color: "white",
                          textDecoration: "none",
                          fontSize: "18px",
                        }}
                      ><li
                          key={index}
                          onClick={() => setIsTrekkingMenuOpen(false)}
                          style={{ listStyle: "none", padding: "3px" }}
                          className="nav-trekking"
                        >
                          
                            {item.name}
                          
                        </li>
                        </NavLink>
                      ))}
                    </ul>
                  </div>
                  <div  style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}>
                    <span
                    style={{
                        color: "white",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}>Annapurna Region</span>
                    <ul  style={{ position: "relative", left: "-40px"}}>
                      {navigationBar["annapurna-region"].map((item, index) => (
                         <NavLink to={item.link} style={{
                            color: "white",
                            textDecoration: "none",
                            fontSize: "18px",
                          }}><li
                          key={index}
                          onClick={() => setIsTrekkingMenuOpen(false)}
                          style={{ listStyle: "none", padding: "3px" }}
                          className="nav-trekking"
                        >
                         {item.name}
                        </li></NavLink>
                      ))}
                    </ul>
                  </div>
                  <div  style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}>
                    <span
                    style={{
                        color: "white",
                        fontSize: "22px",
                        fontWeight: "bold",
                      }}>Langtang Region</span>
                    <ul  style={{ position: "relative", left: "-40px"}}>
                      {navigationBar["langtang-region"].map((item, index) => (
                         <NavLink to={item.link} style={{
                            color: "white",
                            textDecoration: "none",
                            fontSize: "18px",
                          }}><li
                          key={index}
                          onClick={() => setIsTrekkingMenuOpen(false)}
                          style={{ listStyle: "none", padding: "3px" }}
                          className="nav-trekking"
                        >
                         {item.name}
                        </li></NavLink>
                      ))}
                    </ul>
                  </div>
                  <div  style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}>
                    <span
                    style={{
                        color: "white",
                        fontSize: "25px",
                        fontWeight: "bold",
                      }}>Manaslu Region</span>
                    <ul  style={{ position: "relative", left: "-40px"}}>
                      {navigationBar["manaslu-region"].map((item, index) => (
                         <NavLink to={item.link} style={{
                            color: "white",
                            textDecoration: "none",
                            fontSize: "18px",
                          }}><li
                          key={index}
                          onClick={() => setIsTrekkingMenuOpen(false)}
                          style={{ listStyle: "none", padding: "3px" }}
                          className="nav-trekking"
                        >
                         {item.name}
                        </li></NavLink>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </li>
          <li>
            <div
              onMouseEnter={() => setIsActivitiesMenuOpen(true)}
              onMouseLeave={() => setIsActivitiesMenuOpen(false)}
              className="navigation-link"
            >
              Activities
            </div>

            <div
              onMouseEnter={() => setIsActivitiesMenuOpen(true)}
              onMouseLeave={() => setIsActivitiesMenuOpen(false)}
            >
              {isActivitiesMenuOpen && (
                <div style={{position:"absolute"}}>
                  <ul style={{ position: "relative" , top:"10px", padding: "16px", backgroundColor: "var(--primary-color)"}}>
                    {navigationBar.activities.map((item, index) => (
                      <NavLink to={item.link} style={{ color: "white",
                        textDecoration: "none",
                        fontSize: "18px", }}><li
                        key={index}
                        onClick={() => setIsActivitiesMenuOpen(false)}
                        style={{ listStyle: "none", padding: "3px" }}
                        className="nav-trekking"
                      >
                        {item.name}
                      </li></NavLink>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </li>
          <li>
            <div
              onMouseEnter={() => setIsTourMenuOpen(true)}
              onMouseLeave={() => setIsTourMenuOpen(false)}
              className="navigation-link"
            >
              Tours
            </div>

            <div
              onMouseEnter={() => setIsTourMenuOpen(true)}
              onMouseLeave={() => setIsTourMenuOpen(false)}
            >
              {isTourMenuOpen && (
                <div style={{position:"absolute"}}>
                  <ul style={{ position: "relative" , top:"10px", padding: "16px", backgroundColor: "var(--primary-color)"}}>
                    {navigationBar.tours.map((item, index) => (
                      <NavLink to={item.link} style={{ color: "white",
                        textDecoration: "none",
                        fontSize: "18px", }}><li key={index} onClick={() => setIsTourMenuOpen(false)} style={{ listStyle: "none", padding: "3px" }} className="nav-trekking">
                        {item.name}
                      </li></NavLink>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </li>
          <li>
            <NavLink to="/about" className="navigation-link">
              About Us
            </NavLink>
          </li>
          <li>
            <NavLink to="/blogs" className="navigation-link">
              Blog
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" className="navigation-link">
              Contact Us
            </NavLink>
          </li>
        </ul>
        <Search />
      </div>
    </div>
          <div className="mobile">
          <nav className="mobile-nav">
            <div className="navbar-logo">
              <img src="/Image/Nav/big-sky-treks-logo.jpg" alt="" />
            </div>
            <div className="hamburger" onClick={toggleNav}>
              <div className={`${isParallel ? "line" : "cross-first"}`}></div>
              <div className={`${isParallel ? "line" : "cross-second"}`}></div>
              <div className={`${isParallel ? "line" : "cross-third"}`}></div>
            </div>
            {isMenuOpen && (
              <div
                className={`mobile-menu ${
                  isMenuOpen ? "mobile-menu-open" : "mobile-menu-close"
                }`}
              >
                <div className="sub-mobile-menu">
                  <ul className="mobile-menu-list">
                    <Link to={"/"} className="link" style={{textDecoration:"none", color:"white"}} onClick={()=>toggleNav()}><li>Home</li></Link>
                    <hr />
                    {/* <li onClick={toggleTrekkingMenu}> */}
                    {/* <li onClick={toggleActivitiesMenu}>Other Activities */}
                    <li
                      onClick={() => {
                        toggleSubMenu(
                          isActivitiesMenuOpens,
                          setIsActivitiesMenuOpens,
                          isTrekkingMenuOpens,
                          setIsTrekkingMenuOpens,
                          isTourMenuOpens,
                          setIsTourMenuOpens
                        );
                      }}
                    >
                      Activities <CiCircleChevRight />
                      {isActivitiesMenuOpens && (
                        <div className="side-menu activities-side-menu">
                          <Link to={"/other-activities/jungle-safari"} className="link">
                            <div
                              onClick={() => {
                                setIsMenuOpen(false);
                                setIsParallel(true);
                              }}
                            >
                              Jungle Safari
                            </div>
                          </Link>
                          <hr />
                          <Link to={"/other-activities/rafting"} className="link">
                            <div
                              onClick={() => {
                                setIsMenuOpen(false);
                                setIsParallel(true);
                              }}
                            >
                              Rafting
                            </div>
                          </Link>
                          <hr />
                          <Link to={"/other-activities/heli-tour"} className="link">
                            <div
                              onClick={() => {
                                setIsMenuOpen(false);
                                setIsParallel(true);
                              }}
                            >
                              Heli Tour
                            </div>
                          </Link>
                          <hr />
                          <Link to={"/other-activities/mountain-flight"} className="link">
                            <div
                              onClick={() => {
                                setIsMenuOpen(false);
                                setIsParallel(true);
                              }}
                            >
                              Mountain Flight
                            </div>
                          </Link>{" "}
                          <hr />
                          <Link to={"/other-activities/bungee/bungee-jumping"} className="link">
                            <div
                              onClick={() => {
                                setIsMenuOpen(false);
                                setIsParallel(true);
                              }}
                            >
                              Bungee Jumping
                            </div>
                          </Link>
                          <hr />
                          <Link to={"/other-activities/para/paragliding"} className="link">
                            <div
                              onClick={() => {
                                setIsMenuOpen(false);
                                setIsParallel(true);
                              }}
                            >
                              Paragliding
                            </div>
                          </Link>
                          <hr />
                          <Link to={"/other-activities/zip/zip-flyer"} className="link">
                            <div
                              onClick={() => {
                                setIsMenuOpen(false);
                                setIsParallel(true);
                              }}
                            >
                              Zip Flyer
                            </div>
                          </Link>{" "}
                          <hr />
                          <Link to={"/other-activities/bird/bird-watching"} className="link">
                            <div
                              onClick={() => {
                                setIsMenuOpen(false);
                                setIsParallel(true);
                              }}
                            >
                              Bird Watching
                            </div>
                          </Link>
                        </div>
                      )}
                    </li>
                    <hr />
                      <li
                        onClick={() => {
                          toggleSubMenu(
                            isTrekkingMenuOpens,
                            setIsTrekkingMenuOpens,
                            isActivitiesMenuOpens,
                            setIsActivitiesMenuOpens,
                            isTourMenuOpens,
                            setIsTourMenuOpens
                          );
                        }}
                      >
                        Trekking <CiCircleChevRight />
                        {isTrekkingMenuOpens && (
                          <div className="side-menu trekking-side-menu">
                            <Link to={"/trek/everest-region-trek"} onClick={() => toggleNav()} style={{textDecoration:"none", color:"white"}} className="hover"><div>Everest Region</div></Link> <hr />
                            <Link to={"/trek/annapurna-region-trek"} onClick={() => toggleNav()} style={{textDecoration:"none", color:"white"}} className="hover"><div>Annapurna Region</div></Link>
                            <hr />
                            <Link to={"/trek/langtang-region-trek"} onClick={() => toggleNav()} style={{textDecoration:"none", color:"white"}} className="hover"><div>Langtang Region</div></Link>
                            <hr />
                            <Link to={"/trek/manaslu-region-trek"} onClick={() => toggleNav()} style={{textDecoration:"none", color:"white"}} className="hover"><div>Manaslu Region</div></Link>
                          </div>
                        )}
                      </li>
                      <hr />
                    <li
                      onClick={() => {
                        toggleSubMenu(
                          isTourMenuOpens,
                          setIsTourMenuOpens,
                          isTrekkingMenuOpens,
                          setIsTrekkingMenuOpens,
                          isActivitiesMenuOpens,
                          setIsActivitiesMenuOpens
                        );
                      }}
                    >
                      Nepal tours <CiCircleChevRight />
                      {isTourMenuOpens && (
                        <div className="side-menu tour-side-menu">
                          <Link to={"/tours/city-tour"} onClick={() => toggleNav()} style={{textDecoration:"none", color:"white"}}  className="hover"><div>City Tour</div></Link>
                          <hr />
                          <Link to={"/tours/honey/honey-moon-tour"} onClick={() => toggleNav()} style={{textDecoration:"none", color:"white"}}  className="hover"><div>Honeymoon Tour</div></Link>
                          <hr />
                          <Link to={"/tours/pilgrimage-tour"} onClick={() => toggleNav()} style={{textDecoration:"none", color:"white"}}  className="hover"><div>Pilgrime Tour</div></Link>
                          <hr />
                          <Link to={"/tours/photo/photography-tour"} onClick={() => toggleNav()} style={{textDecoration:"none", color:"white"}}  className="hover"><div>Photography Tour</div></Link>
                        </div>
                      )}
                    </li>
                    <hr />
                    <Link to={"/about"} className="link" style={{textDecoration:"none", color:"white"}} onClick={()=>toggleNav()}><li>About</li></Link> <hr />
                    <Link to={"/blogs"} className="link" style={{textDecoration:"none", color:"white"}} onClick={()=>toggleNav()}><li>Blogs</li></Link><hr />
                    <Link to={"/contact"} className="link" style={{textDecoration:"none", color:"white"}} onClick={()=>toggleNav()}><li>Contact</li></Link>
                    <hr />
                  </ul>
                </div>
              </div>
            )}
          </nav>
        </div>
        </>
  );
};

export default Navigation;
