import React, { useState, useEffect } from 'react';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Toggle visibility of the scroll-to-top button
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Attach event listener for scrolling
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <div>
      {isVisible && (
        <div onClick={scrollToTop} style={scrollToTopStyle}>
          <span>^</span> {/* Arrow up symbol */}
        </div>
      )}
    </div>
  );
};

// Styling for the scroll-to-top button
const scrollToTopStyle = {
  position: 'fixed',
  bottom: '90px',
  right: '30px',
  backgroundColor: '#007bff',
  color: 'white',
  borderRadius: '100%',
  width: '50px',
  height: '50px',
//   padding: '10px',
  cursor: 'pointer',
  fontSize: '24px',
  zIndex: 1000,
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default ScrollToTop;
