import React, { useState } from "react";
import blogDetail from "../../../Data/Blogs/BlogDetail.json";
import { useParams } from "react-router-dom";
import Error from "../../Error/components/Error";
import ContactForm from "../../Trekking/components/ContactForm";
import HelmetForSEO from "../../HelmetForSEO";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractFill } from "react-icons/ri";
import "../style/BlogDetail.css";

const BlogDetail = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  let params = useParams();
  if (blogDetail[params.blog]?.url !== params.blog) {
    return <Error />;
  }
  
  return (
    <>
      <HelmetForSEO
        title={`${blogDetail[params.blog]?.subhero.title} - Big Sky Treks`}
        discription={blogDetail[params.blog]?.seo?.description}
        url={`/blogs/${params.blog}`}
        keywords={blogDetail[params.blog]?.seo?.keywords}
      />

      <div className="main-content" style={{  marginTop: "100px" }}>
        <div className="sub-main" style={{ width: "80%", margin: "0 auto" }}>
          <div className="main">
            <h1 className="heading">{blogDetail[params.blog].subhero.title}</h1>
            <p
              className="format"
              style={{ lineHeight: "1.6", margin: "20px 0" }}
              dangerouslySetInnerHTML={{
                __html: blogDetail[params.blog]?.main.paragraph,
              }}
            />


          </div>
          <div className="allcontent">
            {blogDetail[params.blog].content &&
              blogDetail[params.blog].content.map((item, index) => {
                return (
                  <div className="content" key={index} style={{ marginBottom: "30px" }}>
                    <h1 style={{ fontSize: "28px", margin: "10px 0" }}>{item.heading}</h1>
                    {item.image && <img src={item.image} alt="" style={{ width: "100%", borderRadius: "8px" }} />}
                    <div
                      className="format"
                      style={{ lineHeight: "1.6", margin: "10px 0" }}
                      dangerouslySetInnerHTML={{ __html: item.paragraph }}
                    />
                  </div>
                );
              })}
          </div>
          <div className="FAQS" style={{ marginTop: "40px" }}>
            <h2 className="heading" style={{ fontSize: "32px", marginBottom: "20px" }}>FAQs</h2>
            <div
              className="question-answer"
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              {blogDetail[params.blog].faqs.questionAnswer &&
                blogDetail[params.blog].faqs.questionAnswer.map((item, index) => (
                  <div key={index}>
                    <div
                      className="question"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#2c3e50",
                        padding: "15px",
                        color: "white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: "5px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                      onClick={() => handleToggle(index)}
                    >
                      <div style={{ fontSize: "1.1rem" }}>{item.question}</div>
                      <div className={`up-down ${activeIndex === index && "up-down-active"}`}>
                        {activeIndex === index ? <RiSubtractFill /> : <IoMdAdd />}
                      </div>
                    </div>
                    {activeIndex === index && (
                      <div
                        className="answer format"
                        style={{
                          backgroundColor: "#f5f5f5",
                          padding: "15px",
                          color: "#333",
                          borderRadius: "5px",
                          marginTop: "10px",
                          marginBottom: "5px",
                          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                          animation: "fadeIn 0.5s ease",
                          transition: "transform 0.3s ease",
                        }}
                      >
                        {item.answer}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className="contact" style={{ marginTop: "40px" }}>
            <h1 className="heading" style={{ fontSize: "32px", marginBottom: "20px" }}>Book Now</h1>
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail;
