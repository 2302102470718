import React from "react";
import Search from "../../Nav/components/Search";
import "../style/Hero.css";

const Hero = () => {
  return (
    <div style={{ position: "relative" }}>
      <div className="hero">
        <video autoPlay loop muted playsInline className="hero-video">
          <source src="/Image/hero-bg-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <h1 style={{ zIndex: "2" }} className="hero-title">Tailor Made Vacation For Every Style & Interest</h1>
        <div style={{ zIndex: "3" }}>
          <Search />
        </div>
      </div>
    </div>
  );
};

export default Hero;
