import React from "react";
import "../style/Home.css";
import Hero from "../../Components/Home/components/Hero";
import ExploreMore from "../../Components/Home/components/ExploreMore/ExploreMore";
import ExploreNepal from "../../Components/Home/components/ExploreNepal/ExploreNepal";
import EverestBaseCamp from "../../Components/Home/components/EverestBaseCamp/EverestBaseCamp";
import AboutCompany from "../../Components/Home/components/AboutCompany/AboutCompany";
import OtherActivities from "../../Components/Home/components/OtherActivities/OtherActivities";
import HelmetForSEO from "../../Components/HelmetForSEO";
import ItenaryCard from "../../Components/Trekking/components/ItenaryCard";
import Trekking from "../../Data/Trekking/Trekking.json";
import BestSellingTrips from "../../Components/Home/components/BestSellingTrips";

const Home = () => {
  
  return (
    <div>
    <HelmetForSEO 
    title="Big Sky Treks - Discover Nepal's Hidden Gems" 
    discription="Discover unforgettable trekking adventures with Big Sky Treks. We specialize in guided treks across Nepal's breathtaking landscapes, including the Everest Base Camp and Annapurna Circuit. Join us for a once-in-a-lifetime journey." 
    url="/"
    keywords="Big Sky Treks, trekking, Nepal, guided treks, adventure travel, Everest Base Camp, Annapurna Circuit, Gokyo Lake, Everest View Trek, hiking, mountain trekking, travel company"
    />
      <Hero />
      <BestSellingTrips/>
      <EverestBaseCamp />
      <ExploreNepal />
      <div className="about-company">
        <AboutCompany />
      </div>
      <OtherActivities />
    </div>
  );
};

export default Home;
