import React from 'react'
import '../style/Button.css'
import { Link } from 'react-router-dom'
import {motion} from 'framer-motion'
const Button = (props) => {
  return (
    <button  className='button'><Link to={props.link} className='link'>{props.name}</Link></button>
  )
}

export default Button