import React, { useEffect, useState } from "react";
import Subhero from "../../About/components/Subhero";
import Trekking from "../../../Data/Trekking/Trekking.json";
import { NavLink, useParams } from "react-router-dom";

import "../style/Tempelate.css";
import ContactForm from "./ContactForm";
import GeneralInfo from "./GeneralInfo";
import Error from "../../Error/components/Error";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross1 } from "react-icons/rx";

// import HelmetForSEO from "../../HelmetForSEO";
import AdCard from "./AdCard";
import ItenaryCard from "./ItenaryCard";
import HelmetForSEO from "../../HelmetForSEO";
import Calculator from "./Calulator";
import Cards from "../../Home/components/Cards";
import SimilarTreksCards from "./SimilarTreksCards";

const Template = () => {
  const [showLine, setShowLine] = useState(false);
  const ToggleLine = () => {
    return setShowLine(!showLine);
  };
  const params = useParams();
  const URRL = Trekking[params.id]?.url;

  if (!URRL) {
    return (
      <div>
        <Error />
      </div>
    );
  }
let arr=[
  { 
      "name": "Everest Base Camp", 
      "image": "/Image/Trekking/everest-region/everest-base-camp/everest-base-camp1.jpg", 
      "days": "16",
      "link":"/trekking/everest-base-camp",
      "review":"23",
      "price":"1500",
      "facts":"Everest Base Camp: Where Dreams Touch the Sky"

  },
  { 
      "name": "EBC Chola Pass Trek", 
      "image": "/Image/cards/EBC-chola-pass.jpg", 
      "days": "9-16",
      "review":"13",
      "price":"1500",
      "facts":"Choose the path less traveled—Cho La awaits!"
  },
  { 
      "name": "Gokyo Lake Trek", 
      "image": "/Image/cards/Gokyo-lake-trek.jpg", 
      "days": "01",
      "link":"/trekking/langtang-valley-trek",
      "price":"1500",
      "facts":"Gokyo Lake: a stunning reflection of nature’s artistry."
  },
  { 
      "name": "Everest View Trek", 
      "image": "/Image/cards/Everest-view-trek.jpg", 
      "days": "01",
      "link":"/trekking/manaslu-curcit-trek",
      "price":"1250",
      "facts":"The ultimate view of the Himalayas"
  },
  { 
      "name": "Ranjo La Pass Trek", 
      "image": "/Image/cards/Renjo-la-pass-trek.jpg", 
      "days": "01",
      "link":"/trekking/mardi-himal-trek",
      "price":"1650",
      "facts":"Renjo La Pass: a gateway to Himalayan wonder."
  },
  { 
      "name": "Three Pass Trek", 
      "image": "/Image/cards/Three-pass-trek.jpg", 
      "days": "01",
      "link":"/trekking/everest-base-camp-chola-pass",
      "price":"2100",
      "facts":"The three passes of the Everest"
  },
  { 
      "name": "Jiri EBC Trek", 
      "image": "/Image/cards/Jiri-ebc-trek.jpg", 
      "days": "01",
      "link":"/trekking/jomsom-muktinath-trek",
      "price":"2000",
      "facts":"From Jiri to EBC, every step tells a story."
  },
  { 
      "name": "EBC Kongma La Pass Trek", 
      "image": "/Image/cards/EBC-kongma-la-pass-trek.jpg", 
      "days": "01",
      "link":"/trekking/upper-mustang-trek",
      "price":"1470",
      "facts":"The epic journey of the EBC and Kongma La Pass"
  },
  { 
      "name": "Pikey Peak Trek", 
      "image": "/Image/cards/Pikey-peak-trek.jpg", 
      "days":"01",
      "link":"/trekking/annapurna-curcit-trek",
      "price":"800",
      "facts":"Experience tranquility at the summit of Pikey Peak."
      }
]


  return (
    <>

      <HelmetForSEO
        title={`${Trekking[params.id]?.subhero.title} - Big Sky Treks`}
        description={Trekking[params.id].seo.description.content}
        url={`trekking/${params.id}`}
        keywords={Trekking[params.id].seo.keywords.map((key) => {
        return ` ${key}`;
        })}
      />
      <Subhero
        title={Trekking[params.id]?.subhero.title}
        image={Trekking[params.id]?.subhero.image}
      />
      
      <div className="main-content trkking-tempelate-main-content">
        <div className="trekking-tempelate-side-nav side-nav-desktop">
          <div className="trekking-cards">
            <a href="#GeneralInfo">General Info</a>
            <a href="#Overview">Overview</a>
            <a href="#TrekHighlight">Trek Highlight</a>
            <a href="#itenary">Itinerary</a>
            <a href="#IncludeExclude">Include Exclude</a>
            {/* <a href="#SimilarTrek">Similar Trek</a> */}
            <a href="#QuickInquary">Quick Inquiry</a>
          </div>
        </div>
        <div className="trekking-tempelate-side-nav-mobile side-nav-mobile">
          <div className="line">
            {showLine ? (
              <RxCross1 onClick={() => setShowLine(!showLine)} />
            ) : (
              <GiHamburgerMenu onClick={() => setShowLine(!showLine)} />
            )}
          </div>
          {showLine ? (
            <div className="mobile-trekking-cards">
              <a href="#GeneralInfo" onClick={ToggleLine}>
                General Info
              </a>
              <a href="#Overview" onClick={ToggleLine}>
                Overview
              </a>
              <a href="#TrekHighlight" onClick={ToggleLine}>
                Trek Highlight
              </a>
              <a href="#OutlineItenary" onClick={ToggleLine}>
                Outline Itinerary
              </a>
              <a href="#DetailItenary" onClick={ToggleLine}>
                Detail Itinerary
              </a>
              <a href="#IncludeExclude" onClick={ToggleLine}>
                Include Exclude
              </a>
              <a href="#QuickInquary" onClick={ToggleLine}>
                Quick Inquiry
              </a>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div style={{marginLeft:'-30px'}}>
          <div className="info">
            <h2 className="heading">
              {Trekking[params.id].GeneralInfo.heading}
            </h2>
            <div className="info-cards" id="GeneralInfo">
              <GeneralInfo Object={Trekking} Param={params} />
            </div>
          </div>
          <div className="overView" id="Overview">
            <h2 className="heading">{Trekking[params.id]?.overview.heading}</h2>
            <div
              className="format"
              dangerouslySetInnerHTML={{
                __html: Trekking[params.id]?.overview.paragraph,
              }}
            ></div>
          </div>
          <div className="trek-highlights" id="TrekHighlight">
            <h2 className="heading">
              {Trekking[params.id]?.trekHighlights.heading}
            </h2>
            <ol>
              {Trekking[params.id]?.trekHighlights.paragraph.map(
                (item, index) => (
                  <li
                    className="format"
                    dangerouslySetInnerHTML={{ __html: item }}
                    key={index}
                    style={{ fontSize: "20px", marginTop: "5px" }}
                  ></li>
                )
              )}
            </ol>
          </div>
          <div id="itenary">
          <h2 className="heading">{Trekking[params.id]?.detailItenary.heading}</h2>
          <ItenaryCard array={Trekking[params.id]?.detailItenary.itenary} />
          </div>
          <div className="include-exclude" id="IncludeExclude">
            <h2 className="heading">
              {Trekking[params.id]?.includeExclude.heading}
            </h2>
            <div className="include">
              <h2>{Trekking[params.id]?.includeExclude.include.heading}</h2>
              <ul>
                {Trekking[params.id]?.includeExclude.include.lists &&
                  Trekking[params.id]?.includeExclude.include.lists.map(
                    (item, index) => (
                      <li
                        className="format"
                        style={{ fontSize: "20px" }}
                        key={index}
                      >
                        {item}
                      </li>
                    )
                  )}
              </ul>
            </div>
            <div className="exclude">
              <h2>{Trekking[params.id]?.includeExclude.exclude.heading}</h2>
              <ul>
                {Trekking[params.id]?.includeExclude.exclude.lists &&
                  Trekking[params.id]?.includeExclude.exclude.lists.map(
                    (item, index) => (
                      <li
                        className="format"
                        style={{ fontSize: "20px" }}
                        key={index}
                      >
                        {item}
                      </li>
                    )
                  )}
              </ul>
            </div>
          </div>
{/* 
          <div className="similar-trek" id="SimilarTrek">
            <h2 className="heading">
              Similar Treks
            </h2>
             <div style={{ display:"flex", justifyContent:"center", flexWrap:"wrap"}}>
             {
              Trekking["everest-base-camp"].similarTrek.treks.map((item)=>{
                return <NavLink to="/"><SimilarTreksCards
                name={item.title}
                image={item.image}
                days={item.days}
                link={`/similar-trek/${item.url}`}
                /></NavLink>
              })
             }
             </div>
            </div> */}
          <div className="quick-inquary-form" id="QuickInquary">
            <h2 className="heading">
              {Trekking[params.id]?.quickInquaryForm.heading}
            </h2>
            <ContactForm />
          </div>


        </div>
        <div className="calculator">
          {/* <Calculator/> */}
          <AdCard />

        </div>

      </div>

    </>
  );
};

export default Template;
