import React, { useState } from 'react';
import blog from '../../../Data/Blogs/blogs.json';
import BlogsCards from './BlogsCards';

const BlogTempelate = () => {
  // State to track the current page
  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 6;

  // Calculate the total number of pages
  const totalPages = Math.ceil(blog.cards.lists.length / blogsPerPage);

  // Slice the blogs array to display only the items for the current page
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blog.cards.lists.slice(indexOfFirstBlog, indexOfLastBlog);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <section
        className="sub-hero"
        style={{ backgroundImage: `url(https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w600/2023/10/free-images.jpg)` }}
      >
        <div className="hero-content">
          <h1>Blogs</h1>
        </div>
      </section>
      <div className="main-content">
        <div className="sub-main">
          <div className="overview">
            <h1 className="heading">{blog.overview.heading}</h1>
            <p className="format" dangerouslySetInnerHTML={{ __html: blog.overview.paragraph }} />
          </div>
          <div>
            <div className="blog-cards">
              <h1 className="heading">{blog.cards.heading}</h1>
            </div>

            <div className="card-wrapper" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
              {currentBlogs.map((item, index) => (
                <BlogsCards 
                  key={index}
                  link={item.link}
                  image={item.image}
                  month={item.month}
                  day={item.day}
                  heading={item.heading}
                  discription={item.discription}
                  writer={item.writer}
                />
              ))}
            </div>

            {/* Pagination controls */}
            <div
  className="pagination"
  style={{
    display: "flex",
    justifyContent: "center",
    gap: "10px", // Space between the buttons
    marginTop: "20px",
  }}
>
  {Array.from({ length: totalPages }, (_, index) => (
    <button
      key={index}
      className={`page-btn ${currentPage === index + 1 ? 'active' : ''}`}
      onClick={() => handlePageChange(index + 1)}
      style={{
        backgroundColor: currentPage === index + 1 ? "#4CAF50" : "#f1f1f1", // Highlight active page with green
        color: currentPage === index + 1 ? "white" : "#555", // White text on active page
        border: "none",
        padding: "10px 20px",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "16px",
        transition: "background-color 0.3s, transform 0.2s", // Smooth transitions for background color and scaling
      }}
      onMouseEnter={(e) => {
        e.target.style.transform = "scale(1.1)"; // Slight scaling effect on hover
      }}
      onMouseLeave={(e) => {
        e.target.style.transform = "scale(1)"; // Reset scaling effect
      }}
    >
      {index + 1}
    </button>
  ))}
</div>

          </div>
        </div>
      </div>
    </>
  );
};

export default BlogTempelate;
