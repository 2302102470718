import React, { useState } from 'react';
import BestSellingTrip from '../../../Data/Home/BestSellingTrips.json';
import Cards from './Cards';

const BestSellingTrips = () => {
  const [selectedTrip, setSelectedTrip] = useState('everest');
  const [animate, setAnimate] = useState(false); // State to trigger animation

  // Extract tours and activities
  let cityTours = BestSellingTrip.tours['city-tours'];
  let pilgrimageTours = BestSellingTrip.tours['pilgrimage-tours'];
  let photoGraphyTour = BestSellingTrip.tours['photo-graphy-tours'];
  let honeyMoonTours = BestSellingTrip.tours['honey-moon-tours'];
  let tours = [...cityTours, ...pilgrimageTours, ...photoGraphyTour, ...honeyMoonTours];

  let birdWatching = BestSellingTrip.activities['bird-watching'];
  let jungleSafari = BestSellingTrip.activities['jungle-safari'];
  let rafting = BestSellingTrip.activities.rafting;
  let mountainFlight = BestSellingTrip.activities['mountain-flight'];
  let heliTour = BestSellingTrip.activities['heli-tour'];
  let paragliding = BestSellingTrip.activities.paragliding;
  let bungeeJumping = BestSellingTrip.activities['bungee-jumping'];
  let zipFlyer = BestSellingTrip.activities['zip-flyer'];
  let activities = [
    ...birdWatching, 
    ...jungleSafari, 
    ...rafting, 
    ...mountainFlight, 
    ...heliTour, 
    ...paragliding, 
    ...bungeeJumping, 
    ...zipFlyer
  ];

  // Handle trip change (radio selection)
  const handleTripChange = (event) => {
    setSelectedTrip(event.target.value);
  };

  // Render cards in their original order (no shuffle)
  const renderCards = (radioValue, trip) => {
    if (selectedTrip === `${radioValue}`) {
      // Check if trip is defined and is an array
      if (!Array.isArray(trip)) {
        console.warn(`No trips found for ${radioValue}`);
        return null;
      }

      // Take only the first 9 elements (without randomization)
      const limitedTrips = trip.slice(0, 9);

      return limitedTrips.map((trip, index) => (
        <Cards
          key={index}
          link={trip.link}
          image={trip.image}
          name={trip.name}
          days={trip.days}
          facts={trip.facts}
          price={trip.price}
          review={trip.review}
        />
      ));
    }
    return null;
  };

  // Render radio buttons for selecting trips
  const renderRadio = (radioValue, value) => {
    return (
      <label style={labelStyle}>
        <input
          style={
            selectedTrip === radioValue
              ? { ...radioInputStyle, ...radioInputCheckedStyle }
              : radioInputStyle
          }
          type="radio"
          value={radioValue}
          checked={selectedTrip === radioValue}
          onChange={handleTripChange}
        />
        {value}
      </label>
    );
  };

  // Define styles for the radio input and label
  const radioInputStyle = {
    width: '18px',
    height: '18px',
    cursor: 'pointer',
    accentColor: '#007bff',
    marginRight: '10px',
    transition: 'all 0.5s ease-in-out',
    color: 'black',
  };

  const radioInputCheckedStyle = {
    transform: 'scale(1.5)',
  };

  const labelStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: 'black',
  };

  return (
    <div className="best-selling-trips" style={{ margin: 'auto', padding: '0% 0%', marginBottom: '50px' }}>
      <div>
        <h2 style={{ textAlign: 'center', fontSize: '35px' }}>{BestSellingTrip.heading}</h2>
        <div
          style={{
            height: '100px',
            color: 'black',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            width: '90%',
            left: '5%',
            position: 'relative',
          }}
        >
          {renderRadio('everest', 'Everest Region')}
          {renderRadio('annapurna', 'Annapurna Region')}
          {renderRadio('tours', 'Tours')}
          {renderRadio('activities', 'Activities')}
        </div>
      </div>
      <div className="card-wrapper">
        {renderCards('everest', BestSellingTrip['everest-region-trek'])}
        {renderCards('annapurna', BestSellingTrip['annapurna-region-trek'])}
        {renderCards('tours', tours)}
        {renderCards('activities', activities)}
      </div>
    </div>
  );
};

export default BestSellingTrips;
