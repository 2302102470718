import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../Error/components/Error";
import Tour from "../../../Data/Tours/SubTours.json";
import Subhero from "../../About/components/Subhero";
import HelmetForSEO from "../../HelmetForSEO";
// import TourCalc from "./TourCalc";
import Calculator from "../../Trekking/components/Calulator";
const SubSubTour = () => {
  const params = useParams();
  let HTMLrender = () => {
    return (
      <>
        <HelmetForSEO
          url={`/tours/${params.tour}/${params.subtour}`}
          title={`${
            Tour[params.tour][params.subtour].subhero.title
          } - Big Sky Treks`}
          description={Tour[params.tour][params.subtour].seo.description}
          keywords={Tour[params.tour][params.subtour].seo.keywords}
        />
        <Subhero
          title={Tour[params.tour][params.subtour].subhero.title}
          image={Tour[params.tour][params.subtour].subhero.image}
        />
        <div className="main-content">
          <div className="sub-main" 
          style={{
            display:"flex",
            flexDirection:"row",
          }}> 
            <div className="overview">
              <h2 className="headig">
                {Tour[params.tour][params.subtour].overview.heading}
              </h2>
              <p
                className="format"
                dangerouslySetInnerHTML={{
                  __html: Tour[params.tour][params.subtour].overview.paragraph,
                }}
              />
            </div>
            <div>
            <Calculator/>
            </div>

          </div>
        </div>
      </>
    );
  };
  let Checking = () => {
    return Tour[params.tour][params.subtour] == undefined ? (
      <Error />
    ) : (
      <>{HTMLrender()}</>
    );
  };

  return <div>{Checking()}</div>;
};

export default SubSubTour;
