import React from 'react';
import { Link } from 'react-router-dom';
import '../style/Footer.css'; // Import your CSS file for styling

const Footer = () => {
  return (
    <footer className="footerr">
      <div className="footer-inner">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Popular Treks</h3>
            <ul>
              <li><Link to="/trek/everest-region-trek">Everest Region Trek</Link></li>
              <li><Link to="/trek/annapurna-region-trek">Annapurna Region Trek</Link></li>
              <li><Link to="/trek/langtang-region-trek">Langtang Region Trek</Link></li>
              <li><Link to="/trek/manaslu-region-trek">Manaslu Region Trek</Link></li>

            </ul>
          </div>
          <div className="footer-section">
            <h3>Activities</h3>
            <ul>
              <li><Link to="/other-activities/para/paragliding">Paragliding</Link></li>
              <li><Link to="/other-activities/rafting">Rafting</Link></li>
              <li><Link to="/other-activities/bungee/bungee-jumping">Bungee Jumping</Link></li>
              <li><Link to="/other-activities/bird/bird-watching">Bird Watching</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Tours</h3>
            <ul>
              <li><Link to="/tours/city-tour">City Tour</Link></li>
              <li><Link to="/tours/honey/honey-moon-tour">Honeymoon Tour</Link></li>
              <li><Link to="/tours/pilgrimage-tour">Pilgrimage Tour</Link></li>
              <li><Link to="/tours/photo/photography-tour">Photography Tour</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Blogs</h3>
            <ul>
              <li><Link to="/blogs/everest-base-camp">Everest Base Camp</Link></li>
              <li><Link to="/blogs/10-best-things-to-do-in-nepal">10 Things To Do In Nepal</Link></li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Follow Us</h3>
            <ul>
              <li><a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a></li>
              <li><a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a></li>
              <li><a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">Trip Adviser</a></li>
            </ul>
          </div>
        </div>
        </div>
        <hr className="footer-divider" />
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} Big Sky Treks. All rights reserved.</p>
        </div>

    </footer>
  );
};

export default Footer;
