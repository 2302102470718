import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import "../style/Calculator.css";

const Calculator = ({one=2000, two=1800, three=1600, four=1400, five=1200 , six=1000, seven=800}) => {
  let [person, setPerson] = useState(1);
  let [price, setPrice] = useState(2000);
  let [pricePerPerson, setPricePerPerson] = useState(2000);
  let [clicked, setClicked] = useState(false);
  let [fromDate, setFromDate] = useState("");
  let [message, setMessage] = useState("");
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [isLoading, setIsLoading] = useState(false); // State for loading spinner

  const increase = () => {
    setPerson((prev) => {
      const newPerson = prev + 1;
      return validatePerson(newPerson);
    });
  };

  const decrease = () => {
    setPerson((prev) => {
      const newPerson = prev - 1;
      return validatePerson(newPerson);
    });
  };

  const validatePerson = (count) => {
    return count < 1 ? 1 : count;
  };

  const calculatePrice = () => {
    if (person === 1) {
      setPricePerPerson(one);
      return one * person;
    }
    if (person === 2) {
      setPricePerPerson(two);
      return two * person;
    }
    if (person === 3 || person === 4) {
      setPricePerPerson(three);
      return three * person;
    }
    if (person === 5 || person === 6) {
      setPricePerPerson(four);
      return four * person;
    }
    if (person === 7 || person === 8) {
      setPricePerPerson(five);
      return five * person;
    }
    if (person === 9 || person === 10) {
      setPricePerPerson(six);
      return six * person;
    }
    if (person > 10) {
      setPricePerPerson(seven);
      return seven * person;
    }
  };

  useEffect(() => {
    setPrice(calculatePrice());
  }, [person]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading spinner
    if (!name || !email) {
      alert("Please enter your name and email. Please try again.");
      setIsLoading(false); // Stop loading if error occurs
      return;
    }

    const fromDateObj = new Date(fromDate);

    if (isNaN(fromDateObj)) {
      alert("Please enter valid dates. Please try again.");
      setIsLoading(false); // Stop loading if error occurs
      return;
    } 
    else if(fromDateObj < new Date()){
      alert("The 'From' date cannot be in the past. Please try again.");
      setIsLoading(false); // Stop loading if error occurs
      return;
    }
    else if (fromDateObj == new Date()) {
      alert("The 'From' date cannot be today. Please try again.");
      setIsLoading(false); // Stop loading if error occurs
      return;
    }

    const templateParams = {
      user_name: name,
      user_email: email,
      persons: person,
      url: window.location.href,
      price: price,
      pricePerPerson: pricePerPerson,
      from: fromDateObj,
      msg: message,
    };

    emailjs
      .send(
        "service_hgm221g",
        "template_msf18sk",
        templateParams,
        "jOPFan1XJbBUJ1Gs_"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent successfully!");
          setIsLoading(false); // Stop loading on success
          setClicked(false);
          setName("");
          setEmail("");
          setFromDate("");
          setMessage("");
        },
        (error) => {
          console.log(error.text);
          alert("Failed to send email. Please try again.");
          setIsLoading(false); // Stop loading on error
        }
      );
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Calculate Your Price</h2>
      <div style={styles.personContainer}>
        <span style={{fontWeight:"bold", marginTop:"20px" }}>Person:</span>
        <button style={styles.button} onClick={decrease}>
          -
        </button>
        <div style={styles.personCount}>{person}</div>
        <button style={styles.button} onClick={increase}>
          +
        </button>
      </div>
      <div style={styles.priceInfo}>
        Price Per Person: <span style={styles.price}>${pricePerPerson}</span>
      </div>
      <div style={styles.priceInfo}>
        Total Price: <span style={styles.price}>${price}</span>
      </div>
      {clicked ? (
        <div
          style={{
            width: "100%",
            margin: "5px auto",
            padding: "5px",
            textAlign: "center",
            backgroundColor: "#f9f9f9",
          }}
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <div style={styles.dateLabel}>
                <span style={{fontWeight:"bold"}}>From:</span>
                <input
                  type="date"
                  onChange={(e) => setFromDate(e.target.value)}
                  style={styles.dateInput}
                />
              </div>

              <input
                type="text"
                name="name"
                style={styles.input}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Your Name"
              />
              <input
                type="email"
                name="email"
                style={styles.input}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Your Email"
              />
          <textarea
  name="message"
  style={{...styles.input, fontSize: "20px"}}
  onChange={(e) => setMessage(e.target.value)}
  placeholder="Enter Your Message"
></textarea>


              {/* Submit button and loading spinner */}
              <button
                type="submit"
                style={styles.button}
                disabled={isLoading}
              >
                {isLoading ? "Sending..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <button style={styles.button} onClick={() => setClicked(true)}>
          Book Now
        </button>
      )}
    </div>
  );
};



// Inline styles
const styles = {
  container: {
    width: "300px",
    margin: "20px auto",
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    position: "sticky", // This makes the calculator sticky
    top: "120px", // Adjust this value to control the distance from the top of the viewport
    right:"10px",
    
  },
  header: {
    marginBottom: "20px",
    fontSize: "24px",
    color: "#333",
    fontWeight: "600",
  },
  personContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    position:"relative",

  },
  button: {
    padding: "10px 15px",
    fontSize: "18px",
    backgroundColor: "#4169E1",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "0 10px",
    transition: "background-color 0.3s, transform 0.2s",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    marginTop: "20px",
  },
  personCount: {
    fontSize: "20px",
    margin: "0 10px",
    width: "40px",
    textAlign: "center",
    fontWeight: "bold",
  },
  priceInfo: {
    fontSize: "18px",
    color: "#333",
  },
  price: {
    fontWeight: "bold",
    color: "#FF4500",
  },
  input: {
    width: "100%",
    padding: "5px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
  },
  dateLabel: {
display: "flex",
justifyContent: "flex-start",
alignItems: "center",
gap: "10px",

  },
  dateInput: {
    width: "100%",
    padding: "5px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0,0,0,0.15)",
    backgroundColor: "#f9f9f9",
  },
};



export default Calculator;
